import React from 'react';
import '../../css/main.css';
import Posts from '../Functions/BlogPosts';

const Contact: React.FC = () => {
    return (
        <>
            <Posts/>

        </>
    );
}

export default Contact;